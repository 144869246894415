import React, { useCallback, useEffect } from 'react'
import styles from './ModalFull.module.scss'
import cx from 'classnames'
import { ETypography } from '@/interfaces'
import LogoSvg from '@/assets/icons/logo-app.svg'
import CloseSvg from '@/assets/icons/close.svg'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '@/store/store'
import { EButtonType } from '../Button/Button'
import modalSlice from '@/store/reducers/modal'
import dynamic from 'next/dynamic'
import { isInEdition } from '@/utils/TextEditor'
const RequestAccess = dynamic(() => import('@/components/RequestAccess/RequestAccess'), { ssr: false })

const ModalFull = () => {
  const { open } = useSelector((state: RootState) => state.modal)
  const { targetable } = useSelector((state: RootState) => state.launcher)
  const dispatch = useDispatch<AppDispatch>()

  const closeModal = useCallback(() => {
    dispatch(modalSlice.actions.toggleOpen(false))
  }, [dispatch])

  // Events
  useEffect(() => {
    const focusArea = (e: KeyboardEvent) => {
      if (e.code === 'KeyL' && !isInEdition(e)) {
        if (!targetable) {
          e.preventDefault()
          e.stopPropagation()
          dispatch(modalSlice.actions.toggleOpen(true))
        }
      } else if (e.code === 'Escape') {
        e.preventDefault()
        e.stopPropagation()
        closeModal()
      }
    }

    window.addEventListener('keydown', focusArea, true)

    return () => {
      window.removeEventListener('keydown', focusArea, true)
    }
  }, [closeModal, dispatch, targetable])

  return (
    <div className={cx(styles.container, { [styles.show]: open })} onClick={closeModal}>
      <div
        className={styles.content}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <div className={styles.closeBox} onClick={closeModal}>
          <CloseSvg className={styles.closeIcon} />
        </div>
        <div className={styles.inner}>
          <div className={styles.logoContainer}>
            <LogoSvg className={styles.logo} />
          </div>
          <div className={cx(styles.title, ETypography.subtitle)}>Request Access</div>
          <p className={styles.text}>
            We&apos;re excited for you to capture with Lazy. <br />
            We&apos;ll be sending invites gradually over time.
          </p>
          <div className={styles.bottom}>
            <RequestAccess ctaText="Become one" column btnType={EButtonType.modalFull} autoFocus={open} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalFull
