import { useEffect, useState } from 'react'

export const usePointerCoords = () => {
  const [pointerCoords, setPointerCoords] = useState({ x: 0, y: 0 })

  useEffect(() => {
    const handlePointerMove = (e: PointerEvent) => {
      setPointerCoords({ x: e.clientX, y: e.clientY })
    }

    window.addEventListener('pointermove', handlePointerMove)

    return () => {
      window.removeEventListener('pointermove', handlePointerMove)
    }
  })

  return pointerCoords
}
