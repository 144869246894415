import React, { useCallback } from 'react'
import styles from './Header.module.scss'
import LogoSvg from '@/assets/icons/logo-w-title.svg'
import Button, { EButtonType } from '@/components/Button/Button'
import Container from '@/components/Container/Container'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '@/store/store'
import cx from 'classnames'
import modalSlice from '@/store/reducers/modal'
import Link from 'next/link'
import { useScrollPosition } from 'hooks/useScrollPosition'

const Header = () => {
  const scrollPos = useScrollPosition()
  const { headerAppear } = useSelector((state: RootState) => state.global)
  const dispatch = useDispatch<AppDispatch>()

  const handleClickBtn = useCallback(() => {
    dispatch(modalSlice.actions.toggleOpen(true))
  }, [dispatch])

  return (
    <div
      className={cx(styles.header, {
        [styles.background]: scrollPos > 0,
        [styles.show]: headerAppear || scrollPos > 0,
      })}
    >
      <Container>
        <div className={cx(styles.inner)}>
          <Link href="/">
            <div className={styles.logo}>
              <LogoSvg className={styles.icon}></LogoSvg>
              {/* <p className={styles.lazy}>Lazy</p> */}
            </div>
          </Link>
          <div>
            <Link href="https://vip.lazy.so">
              <p className={styles.lazy}>Log in</p>
            </Link>
            <Button type={EButtonType.icon} text="Get Lazy" onClick={handleClickBtn}></Button>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Header
