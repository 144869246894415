export const isInEdition = (e: KeyboardEvent) => {
  // @ts-ignore
  return ['TEXTAREA', 'INPUT'].includes(e?.target?.tagName) || document.getElementById(editorId)?.editor?.isFocused
}

export const focusEditor = () => {
  // @ts-ignore
  return document.getElementById(editorId)?.editor?.commands.focus()
}
// @ts-ignore
export const getEditor = () => document.getElementById(editorId)?.editor
export const editorId = 'launcher_editor'
