import React from 'react'
import styles from './Footer.module.scss'
import LogoSvg from '@/assets/icons/logo-w-title.svg'
import TwitterSvg from '@/assets/icons/twitter.svg'
import DribbbleSvg from '@/assets/icons/dribbble.svg'
import LinkedinSvg from '@/assets/icons/linkedin.svg'
import Container from '@/components/Container/Container'
import dynamic from 'next/dynamic'
import Link from 'next/link'
const FooterCanvas = dynamic(() => import('@/components/FooterCanvas/FooterCanvas'), { ssr: false })

const Footer = () => {
  return (
    <div className={styles.footer}>
      <FooterCanvas />
      <Container>
        <div className={styles.top}>
          <Link href="/">
            <div className={styles.logo}>
              <LogoSvg className={styles.icon}></LogoSvg>
              {/* <p className={styles.lazy}>Lazy</p> */}
            </div>
          </Link>
          {/* <div className={styles.center}>We’re Hiring!</div> */}
          <div className={styles.socials}>
            <a href="https://twitter.com/lazyapphq" rel="noreferrer" target="_blank" className={styles.social}>
              <TwitterSvg />
            </a>
            <a href="https://dribbble.com/lazyapp" rel="noreferrer" target="_blank" className={styles.social}>
              <DribbbleSvg />
            </a>
            <a
              href="https://www.linkedin.com/company/lazy-app/about/"
              rel="noreferrer"
              target="_blank"
              className={styles.social}
            >
              <LinkedinSvg />
            </a>
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.copyrights}>©Lazy 2024. All rights reserved.</div>
          <div className={styles.terms}>
            {/* <a href="mailto:care@lazy.so?subject=Hey from lazy.so">Contact us</a> ·{' '} */}
            {/* <Link href="/privacy-policy">Privacy Policy</Link> ·{' '} */}
            {/* <Link href="/terms-and-conditions">Terms and Conditions</Link> */}
            <a href="https://twitter.com/lazyapphq" rel="noreferrer" target="_blank">
              Follow @lazyappHQ for invites
            </a>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Footer
