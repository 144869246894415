import React, { CSSProperties, ReactNode } from 'react'
import styles from './Container.module.scss'
import cx from 'classnames'

type Props = {
  children: ReactNode
  big?: boolean
  small?: boolean
  styleInline?: CSSProperties
}

const Container = ({ children, big, small, styleInline }: Props) => {
  return (
    <div className={cx(styles.container, { [styles.big]: big, [styles.small]: small })} style={styleInline}>
      {children}
    </div>
  )
}

export default Container
