import React, { CSSProperties, memo, useCallback, useEffect, useRef, useState } from 'react'
import styles from './Button.module.scss'
import cx from 'classnames'
import LSvg from '@/assets/icons/L.svg'
import { ETypography } from '@/interfaces'
import { useInView } from 'react-intersection-observer'
import gsap from 'gsap'
import { usePointerCoords } from 'hooks/usePointerCoords'
import { useScrollPosition } from 'hooks/useScrollPosition'

export enum EButtonType {
  icon = 'icon',
  modalFull = 'modalFull',
}

type Props = {
  type?: EButtonType
  text?: string
  styleInline?: CSSProperties
  onClick?: () => void
}

const Button = ({ type, text, styleInline, onClick }: Props) => {
  const ref = useRef<HTMLDivElement>()
  const [boundingRect, setBoundingRect] = useState<DOMRect>()

  const scrollPos = useScrollPosition()
  const pointerMove = usePointerCoords()

  // check is in view
  const [inViewRef, inView] = useInView({
    /* Optional options */
    threshold: 0,
  })
  // Use `useCallback` so we don't recreate the function on each render - Could result in infinite loop
  const setRefs = useCallback(
    (node) => {
      // Ref's from useRef needs to have the node assigned to `current`
      ref.current = node
      // Callback refs, like the one from `useInView`, is a function that takes the node as an argument
      inViewRef(node)
    },
    [inViewRef],
  )

  useEffect(() => {
    if (ref.current && inView) {
      const rect = ref.current.getBoundingClientRect()
      setBoundingRect(rect)
    }
  }, [ref, scrollPos, inView])

  useEffect(() => {
    if (ref.current && boundingRect && inView) {
      ref.current.style.setProperty('--x', `${pointerMove.x - boundingRect.left}px`)
      ref.current.style.setProperty('--y', `${pointerMove.y - boundingRect.top}px`)
    }
  }, [boundingRect, pointerMove, inView])

  const handleClick = useCallback(() => {
    onClick()
    const tl = gsap.timeline()
    tl.to(ref.current, {
      scale: 0.96,
      duration: 0.15,
    })
    tl.to(ref.current, {
      scale: 1,
      duration: 0.15,
    })
  }, [onClick])

  return (
    <div
      ref={setRefs}
      className={cx(styles.button, { [styles.modalFull]: type === EButtonType.modalFull })}
      style={styleInline}
      onClick={handleClick}
    >
      <div className={styles.gradient}></div>
      <div className={styles.content}>
        <p className={cx(styles.text, { [ETypography.small]: type === EButtonType.icon })}>{text}</p>
        {type === EButtonType.icon && <LSvg className={styles.icon}></LSvg>}
      </div>
    </div>
  )
}

export default memo(Button)
