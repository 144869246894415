import React, { ReactNode } from 'react'
import Head from 'next/head'
import Script from 'next/script'

type Props = {
  children?: ReactNode
  title?: string
  description?: string
  socialImgOg?: string
  socialImgTwitter?: string
  url?: string
  GTAG_ID?: string
}

const Layout = ({
  children,
  title = 'Lazy ⋅ A capture tool for knowledge',
  description = 'One keyboard shortcut to save every idea, link, tweet and so much more',
  url,
  socialImgOg,
  socialImgTwitter,
  GTAG_ID,
}: Props) => (
  <>
    <Head>
      <title>{title}</title>
      <meta charSet="utf-8" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />

      {/* <!-- meta tags --> */}
      <meta name="description" content={description} />
      <link rel="canonical" href={url} />

      {/* <!-- socials meta tags --> */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={socialImgOg} />
      <meta property="og:url" content={url} />
      <meta property="og:site_name" content={title} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={socialImgTwitter} />
      <meta name="twitter:image:alt" content={title} />
      <meta name="twitter:site" content="@lazyappHQ" />

      {/* favicon */}
      <link rel="apple-touch-icon" sizes="180x180" href="/favicon_io/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon_io/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon_io/favicon-16x16.png" />
      <link rel="manifest" href="/favicon_io/site.webmanifest" />
    </Head>
    {children}
    <Script src={`https://www.googletagmanager.com/gtag/js?id=${GTAG_ID}`} strategy="afterInteractive" />
    <Script id="google-analytics" strategy="afterInteractive">
      {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${GTAG_ID}');
        `}
    </Script>
  </>
)

export default Layout
