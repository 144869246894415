// You can include shared interfaces/types in a separate file
// and then use them in any component by importing them. For
// example, to import the interface below do:
//
// import { User } from 'path/to/interfaces';

export type User = {
  id: number
  name: string
}

export enum ETypography {
  sh1 = 'sh1',
  mh1 = 'mh1',
  sh2 = 'sh2',
  mh2 = 'mh2',
  sh2footer = 'sh2footer',
  mh2footer = 'mh2footer',
  sh3 = 'sh3',
  sh4 = 'sh4',
  mh4 = 'mh4',
  subtitle = 'subtitle',
  subtitleM = 'subtitleM',
  body = 'body',
  small = 'small',
}

export enum EAppearAnimation {
  show = 'show',
  opacity = 'appear',
  fromBottom = 'appearFromBottom',
  scale = 'appearScale',
  bounce = 'appearBounce',
  delay1 = 'delay1',
  delay2 = 'delay2',
  delay3 = 'delay3',
}

export type TPointCoord = {
  x: number
  y: number
}
